import React from "react"

import Seo from "components/Seo"
import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import BanerContact from "components/BanerContact"

import { Content } from "page_components/config-sample"

import ConfigProvider from "context/config"
import { useProducts } from "context/products"

const ConfigSamples = ({ location }) => {
  const { productsEN } = useProducts()

  const formatted_products = productsEN?.filter(item =>
    item?.productCategories?.nodes?.some(item => item?.slug === "tapety")
  )

  return (
    <ConfigProvider location={location} products={formatted_products}>
      <Content />
    </ConfigProvider>
  )
}

const Samples = ({ location }) => {
  return (
    <Layout location={location}>
      <Breadcrumbs title="Samples" />
      <ConfigSamples location={location} />
      <BanerContact />
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Order a Sample - Test Wallpapers"
    description="Order a sample measuring 30x60 cm. Beautiful sample patterns and textures will help you see how they suit your interior before placing an order."
  />
)

export default Samples
